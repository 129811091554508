
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { AccountingDocumentTypeEnum } from '@gid/models';

@Component({})
export default class PaymentAmount extends Vue {
  @Prop({ required: true }) amount!: number;
  @Prop() documentType!: string;

  get shownAmount() {
    if (
      this.documentType &&
      this.documentType === AccountingDocumentTypeEnum.CREDIT_NOTE
    ) {
      return -this.amount;
    }
    return this.amount;
  }

  get textClass() {
    return {
      'text-danger': this.shownAmount < 0,
      'text-success': this.shownAmount > 0,
      'bg-white': true,
    };
  }
}
